<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
      "Go to store": "Ir para a loja virtual",
      Menu: "Menu",
      Dashboard: "Painel",
      Register: "Cadastrar",
      Courses: "Cursos",
      Store: "Loja",
      Products: "Produtos",
      Cart: "Carrinho",
      "My Orders": "Meus Pedidos",
      Wallet: "Carteira",
      Extract: "Extrato",
      Network: "Rede",
      Profit: "Rendimento",
      Activation: "Ativação",
      Balance: "Saldo",
      Voucher: "Voucher",
      Transfer: "Transferir",
      Pay: "Pagar",
      Withdrawal: "Sacar",
      "Withdrawal History": "Histórico de Saque",
      Sponsored: "Indicados",
      Team: "Equipe",
      Binary: "Binário",
      Reports: "Relatórios",
      "Bonus Referral": "Bônus Indicação",
      "Bonus Team": "Bônus Equipe",
      "Bonus Shopping": "Bônus Compras",
      Graduation: "Graduação",
      Support: "Suporte",
      Downloads: "Downloads",
      FAQ: "FAQ",
      Tickets: "Tickets",
      Points: "Pontos",
      "Monthly Activation": "Ativação Mensal",
    },
    es: {
      "Go to store": "Ir a la tienda virtual",
      Menu: "Menu",
      Dashboard: "Panel",
      Register: "Registro",
      Courses: "Cursos",
      Store: "Tienda",
      Products: "Productos",
      Cart: "Cesta",
      "My Orders": "Mis Pedidos",
      Wallet: "Cartera",
      Extract: "Extracto",
      Network: "Red",
      Profit: "Rendimiento",
      Activation: "Activación",
      Balance: "Saldo",
      Voucher: "Cupón",
      Transfer: "Transferir",
      Pay: "Pagar",
      Withdrawal: "Retirada",
      "Withdrawal History": "Historia de Retirada",
      Sponsored: "Patrocinados",
      Team: "Equipo",
      Binary: "Binario",
      Reports: "Informes",
      "Bonus Referral": "Bonus Patrocinio",
      "Bonus Team": "Bônus Equipo",
      "Bonus Shopping": "Bônus Compras",
      Graduation: "Graduación",
      Support: "Soporte",
      Downloads: "Descargas",
      FAQ: "FAQ",
      Tickets: "Tickets",
      Points: "Puntos",
      "Monthly Activation": "Activación Mensual",
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  mounted: function () {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
  },
};
</script>

<template>
  <div id="sidebar-menu">
    <ul id="side-menu" class="metismenu list-unstyled mt-1">
      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
          <span class="notranslate">{{ t("Dashboard") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/courses" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
            <path d="M2 7v1l11 4 9-4V7L11 4z"></path>
            <path d="M4 11v4.267c0 1.621 4.001 3.893 9 3.734 4-.126 6.586-1.972 7-3.467.024-.089.037-.178.037-.268V11L13 14l-5-1.667v3.213l-1-.364V12l-3-1z"></path>
          </svg>
          <span class="notranslate">{{ t("Courses") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/investments/types" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
            <path d="M16 2H8C4.691 2 2 4.691 2 8v13a1 1 0 0 0 1 1h13c3.309 0 6-2.691 6-6V8c0-3.309-2.691-6-6-6zm4 14c0 2.206-1.794 4-4 4H4V8c0-2.206 1.794-4 4-4h8c2.206 0 4 1.794 4 4v8z"></path>
            <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4z"></path>
          </svg>
          <span class="notranslate">{{ t("Investir") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/investments/contracts" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
            <path
              d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z">
            </path>
            <path d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z"></path>
          </svg>
          <span class="notranslate">{{ t("Contratos") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/network/sponsored" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
            <path d="M15 11h7v2h-7zm1 4h6v2h-6zm-2-8h8v2h-8zM4 19h10v-1c0-2.757-2.243-5-5-5H7c-2.757 0-5 2.243-5 5v1h2zm4-7c1.995 0 3.5-1.505 3.5-3.5S9.995 5 8 5 4.5 6.505 4.5 8.5 6.005 12 8 12z">
            </path>
          </svg>
          <span class="notranslate">{{ t("Meus Indicados") }}</span>
        </router-link>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M2 8v4.001h1V18H2v3h16l3 .001V21h1v-3h-1v-5.999h1V8L12 2 2 8zm4 10v-5.999h2V18H6zm5 0v-5.999h2V18h-2zm7 0h-2v-5.999h2V18zM14 8a2 2 0 1 1-4.001-.001A2 2 0 0 1 14 8z"></path>
          </svg>
          <span class="notranslate">Financeiro</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/wallet/extract" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Extract") }}</span>
            </router-link>
          </li>
          <li class="d-none">
            <router-link tag="a" to="/wallet/transfer" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Transfer") }}</span>
            </router-link>
          </li>
          <li class="d-none">
            <router-link tag="a" to="/wallet/pay" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Pay") }}</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/wallet/withdrawal" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Withdrawal") }}</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/wallet/withdrawal/history" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Withdrawal History") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 21h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM5 5h14l.001 14H5V5z"></path>
            <path d="m13.553 11.658-4-2-2.448 4.895 1.79.894 1.552-3.105 4 2 2.448-4.895-1.79-.894z"></path>
          </svg>
          <span class="notranslate">Bonificações</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/reports/indicacao" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Indicação") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link tag="a" to="/downloads" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="m12 16 4-5h-3V4h-2v7H8z"></path>
            <path d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"></path>
          </svg>
          <span class="notranslate">{{ t("Downloads") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/faq" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M12 6a3.939 3.939 0 0 0-3.934 3.934h2C10.066 8.867 10.934 8 12 8s1.934.867 1.934 1.934c0 .598-.481 1.032-1.216 1.626a9.208 9.208 0 0 0-.691.599c-.998.997-1.027 2.056-1.027 2.174V15h2l-.001-.633c.001-.016.033-.386.441-.793.15-.15.339-.3.535-.458.779-.631 1.958-1.584 1.958-3.182A3.937 3.937 0 0 0 12 6zm-1 10h2v2h-2z">
            </path>
            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z">
            </path>
          </svg>
          <span class="notranslate">{{ t("FAQ") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/tickets" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4 1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10z">
            </path>
          </svg>
          <span class="notranslate">{{ t("Tickets") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.py-2 {
  padding: 0.9rem 1.5rem 0.7rem !important;
}
.py-2 i,
.py-2 span {
  line-height: 0px !important;
  padding-bottom: 0 !important;
}
.py-2 i {
  margin-top: -4px;
}
</style>